import { useEffect } from 'react';

import { INTERCOM_APP_ID } from '@/components/common/inline-scripts';
import { PreviewBanner } from '@/components/common/PreviewBanner';
import { SEOTags } from '@/components/layout/SharedLayout/SeoTags';
import { SiteHead } from '@/components/layout/SiteHead';
import { DYRecommendationContext, useActiveConsent } from '@/lib/dynamicYield';
import { usePageCommon } from '@/lib/usePageCommon';

import type { DynamicYieldProps } from '@/lib/dynamicYield';
import type { SanitySEOType } from '@/types/sanity';
import type { LocaleTranslations } from '@/types/shared';
import type { ReactNode } from 'react';

export interface SharedLayoutProps {
  localeTranslations?: LocaleTranslations;
  canonicalPagePath?: string;
  SEO?: SanitySEOType;
  children?: ReactNode;
  dynamicYield: DynamicYieldProps;
}

export const SharedLayout = ({
  children,
  localeTranslations,
  canonicalPagePath,
  SEO,
  dynamicYield,
}: SharedLayoutProps) => {
  const { siteSettings, preview } = usePageCommon();

  useEffect(() => {
    if (
      siteSettings?.isIntercomEnabled &&
      typeof window?.Intercom !== 'undefined'
    ) {
      window?.Intercom('boot', {
        app_id: `${INTERCOM_APP_ID}`,
        api_base: 'https://api-iam.eu.intercom.io',
      });
    }
  }, [siteSettings?.isIntercomEnabled]);

  useActiveConsent();

  return (
    <>
      <DYRecommendationContext dynamicYield={dynamicYield} />

      <SiteHead SEO={SEO} />
      <SEOTags
        localeTranslations={localeTranslations}
        canonicalPagePath={canonicalPagePath}
      />
      {children}
      {Boolean(preview) && <PreviewBanner />}
    </>
  );
};
