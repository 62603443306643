import { createContext, useContext } from 'react';

import type { BackgroundColourTokenType } from '@/types/colours';
import type { SiteSettings } from '@/types/shared';

export type LayoutControls = {
  spacingMode?: 'padding' | 'margin';
  firstItemBackground?: BackgroundColourTokenType;
  lastItemBackground?: BackgroundColourTokenType;
};

type PageCommonContextType = {
  preview?: boolean;
  siteSettings?: SiteSettings;
  // TODO: Wire these up
  // audience?: SanityAudienceType;

  layout?: LayoutControls;
};

const PageCommonContext = createContext<PageCommonContextType>(null);

export const PageCommonProvider = ({
  children,
  ...rest
}: PageCommonContextType & { children: React.ReactNode }) => {
  return (
    <PageCommonContext.Provider value={rest ?? {}}>
      {children}
    </PageCommonContext.Provider>
  );
};

export const usePageCommon = () => {
  return useContext(PageCommonContext);
};
