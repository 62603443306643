import { AudienceSwitcher } from '@/components/layout/NavigationBar/AudienceSwitcher';
import { DesktopNav } from '@/components/layout/NavigationBar/DesktopNav';
import { MobileNav } from '@/components/layout/NavigationBar/MobileNav';

import type { SanityAudienceType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

export interface NavigationBarProps {
  navigation: Navigation_exType;
  audiences?: SanityAudienceType[];
  isSticky?: boolean;
  siteSettings: SiteSettings;
}

export const NavigationBar = ({
  navigation,
  audiences,
  siteSettings,
  isSticky = true,
}: NavigationBarProps) => {
  return (
    <>
      {audiences && (
        <AudienceSwitcher
          audiences={audiences}
          siteSettings={siteSettings}
          activeAudience={navigation?.audience}
        />
      )}
      {navigation && navigation?.navItems?.length > 0 && (
        <>
          <DesktopNav
            navigation={navigation}
            isAudienceSwitcherEnabled={siteSettings?.isAudienceSwitcherEnabled}
            isSticky={isSticky}
          />
          <MobileNav
            navigation={navigation}
            isAudienceSwitcherEnabled={siteSettings?.isAudienceSwitcherEnabled}
            isSticky={isSticky}
          />
        </>
      )}
    </>
  );
};
