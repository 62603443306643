import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { RichHeading } from '@/components/common/Heading';
import { HeadingLevel } from '@/components/common/MarkUp';
import { itemRender } from '@/components/layout/NavigationBar/SubNavigation';
import { NavBlogPost } from '@/components/layout/NavigationBar/SubNavigation/NavBlogPost';

import type {
  NavCardListRegion,
  NavDirectLink,
  NavLinkGridRegion,
  NavLinkListRegion,
  NavPanel,
  Region as RegionType,
} from '@/components/layout/NavigationBar/types';
import type { SanityAudienceType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

interface SubNavSplitLayoutProps {
  navItem: NavPanel | NavDirectLink;
  navigationAudience?: SanityAudienceType;
  isAudienceSwitcherEnabled?: boolean;
}

export const SubNavSplitLayout = ({
  navItem,
  navigationAudience,
  isAudienceSwitcherEnabled,
}: SubNavSplitLayoutProps) => {
  const navLinkListRegion = (region: NavLinkListRegion) => {
    return (
      <Region key={region._key} numberOfColumns={region?.columns?.length || 1}>
        <HeadingLevel>
          {region.columns.map((column) => {
            return (
              <Column key={column._key}>
                {column.items.map((item) => {
                  return itemRender(
                    item,
                    navigationAudience,
                    isAudienceSwitcherEnabled,
                  );
                })}
              </Column>
            );
          })}
        </HeadingLevel>
      </Region>
    );
  };

  const navLinkGridRegion = (region: NavLinkGridRegion) => {
    return (
      <Region key={region._key} numberOfColumns={2}>
        <HeadingLevel>
          {region.items.map((item) => {
            return (
              <Column key={item._key}>
                {itemRender(
                  item,
                  navigationAudience,
                  isAudienceSwitcherEnabled,
                )}
              </Column>
            );
          })}
        </HeadingLevel>
      </Region>
    );
  };

  const navCardListRegion = (
    region: NavCardListRegion,
    audienceConfig: BlockWithAudience,
  ) => {
    return (
      <NavCardListColumn>
        <HeadingLevel>
          {region.items.map((item) => {
            if (item._type === 'headerItem') {
              return itemRender(item);
            }
            if (item._type === 'cardItem') {
              return (
                <NavBlogPost key={item._key} {...item} {...audienceConfig} />
              );
            }
          })}
        </HeadingLevel>
      </NavCardListColumn>
    );
  };

  const renderRegion = (
    region: RegionType,
    audienceConfig?: BlockWithAudience,
  ) => {
    switch (region._type) {
      case 'navLinkGridRegion':
        return navLinkGridRegion(region);
      case 'navLinkListRegion':
        return navLinkListRegion(region);
      case 'navCardListRegion':
        return navCardListRegion(region, audienceConfig);
      default:
        return null;
    }
  };

  if (navItem && navItem._type !== 'navDirectLink') {
    const { regions, separator } = navItem;
    const regionOne = regions?.[0];
    const regionTwo = regions?.[1];
    const regionThree = regions?.[2];
    return (
      <Container>
        <LeftColumn>
          {navItem?.heading && (
            <HeadingLevel>
              <RichHeading heading={navItem.heading} />
            </HeadingLevel>
          )}
          {regionOne && renderRegion(regionOne)}
          {separator === 'horizontal' && regionThree && <HorziontalSeparator />}
          {regionThree && renderRegion(regionThree)}
        </LeftColumn>
        <RightColumn verticalSeparator={separator === 'vertical'}>
          {regionTwo &&
            renderRegion(regionTwo, {
              pageAudience: navigationAudience,
              isAudienceSwitcherEnabled,
            })}
        </RightColumn>
      </Container>
    );
  }
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-bottom: var(--space-block-md);
`;
const Region = styled.div<{ numberOfColumns: number }>`
  display: grid;
  grid-template-columns: ${({ numberOfColumns }) =>
    `repeat(${numberOfColumns}, 1fr);`};
  row-gap: var(--space-block-sm);
  column-gap: var(--space-block-lg);

  :first-of-type {
    padding-top: var(--space-block-md);
    padding-bottom: var(--space-block-md);
  }

  :last-of-type {
    padding-top: var(--space-block-sm);
  }
  ${screen.lg} {
    column-gap: var(--space-block-xl);
  }
`;
const Column = styled.div`
  height: min-content;
`;

const NavCardListColumn = styled.div`
  display: none;
  ${screen.md} {
    display: grid;
    height: min-content;
    gap: var(--space-component-lg);
  }
`;
const LeftColumn = styled.div`
  margin-right: var(--space-block-md);

  ${screen.lg} {
    margin-right: var(--space-block-lg);
  }
`;
const RightColumn = styled.div<{ verticalSeparator: boolean }>`
  border-left: ${({ verticalSeparator }) =>
    verticalSeparator && '1px solid var(--border-warm-subtle)'};
  padding-left: var(--space-block-md);

  ${screen.lg} {
    padding-left: var(--space-block-lg);
  }
`;

const HorziontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: var(--border-warm-subtle);
`;
