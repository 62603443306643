import { useState } from 'react';
import styled from '@emotion/styled';

import { NavigationLink } from '@/components/layout/NavigationBar/NavLink';
import { blockUrlWithAudience } from '@/lib/utils';

import type { SanityAudienceType } from '@/types/sanity';
import type { Navigation_exType } from '@/types/shared';
import type { Dispatch, PointerEvent, SetStateAction } from 'react';

interface DesktopNavListProps {
  items: Navigation_exType['navItems'];
  activeItem: string | null;
  setActiveItem: Dispatch<SetStateAction<string | null>>;
  navigationAudience?: SanityAudienceType;
  isAudienceSwitcherEnabled?: boolean;
  navMouseEnter: (e: PointerEvent<HTMLAnchorElement>) => void;
}

export const mobileBreakpoint = 1250;

export const DesktopNavLinks = ({
  items,
  activeItem,
  setActiveItem,
  navigationAudience,
  isAudienceSwitcherEnabled,
  navMouseEnter,
}: DesktopNavListProps) => {
  const [isFirstOpen, setIsFirstOpen] = useState(true);

  const handleOpen = (id) => {
    setActiveItem(id);
  };

  const handleClose = () => {
    setActiveItem(null);
  };

  return (
    <>
      <NavList onPointerLeave={() => setIsFirstOpen(true)}>
        {items.map(({ title, url, _key, _type, noAudienceToggle }, index) => (
          <NavigationLink
            key={_key}
            handleOpen={handleOpen}
            handleClose={handleClose}
            id={_key}
            href={blockUrlWithAudience(
              url,
              navigationAudience,
              navigationAudience,
              noAudienceToggle,
              isAudienceSwitcherEnabled,
            )}
            title={title}
            index={index}
            isActive={activeItem === _key}
            isOtherActive={activeItem && activeItem !== _key}
            linkOnly={_type === 'navDirectLink'}
            isFirstOpen={isFirstOpen}
            setIsFirstOpen={setIsFirstOpen}
            navMouseEnter={navMouseEnter}
          />
        ))}
      </NavList>
    </>
  );
};

const NavList = styled.div`
  display: none;

  @media (min-width: ${mobileBreakpoint}px) {
    display: grid;
    align-items: center;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
  }
`;
