import { useMemo } from 'react';
import { useCookieValue } from '@react-hookz/web/cjs/useCookieValue';

import type { SanityAudienceType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

export const useGetNavigation = (
  navigationCollection: Navigation_exType[],
  siteSettings: SiteSettings,
  audienceRef?: SanityAudienceType,
) => {
  const [cookieValue, set] = useCookieValue('AudienceType', {
    path: '/',
    initializeWithValue: false,
  });

  return useMemo<Navigation_exType>(() => {
    // If AudienceSwitcher is disabled, show first of navigationCollection, sorted by audience order
    if (!siteSettings.isAudienceSwitcherEnabled) {
      return navigationCollection[0];
    }

    if (audienceRef) {
      const referencedNavigation_ex = navigationCollection.find(
        (nav_ex) => nav_ex.audience?._id === audienceRef._id,
      );

      if (referencedNavigation_ex) {
        const cookieName = referencedNavigation_ex.audience.name.toLowerCase();

        set(cookieName);
        return referencedNavigation_ex;
      }
    }

    const navigationByCookie = navigationCollection.find(
      (nav) => nav.audience?.name.toLowerCase() === cookieValue,
    );

    return navigationByCookie || navigationCollection[0];
  }, [
    navigationCollection,
    siteSettings?.isAudienceSwitcherEnabled,

    cookieValue,
    audienceRef,
    set,
  ]);
};
